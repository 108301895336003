import { AddressGeometry, UserInputAddress } from 'context/KYC/types';
import formatString from 'format-string-by-pattern';
import _ from 'lodash';
import { PlaceDetails } from 'types';
import moment from 'moment';

const DIGIT_REGEX = /[^\d]/g;

export const formatDate = (target: string) => {
  const DATE_PATTERN = 'XX/XX/XXXX';
  const onlyNumbers = target.replace(DIGIT_REGEX, '');
  return formatString(DATE_PATTERN, onlyNumbers);
};

export const formatPhone = (target: string) => {
  const PHONE_PATTERN = '(XXX) XXX-XXXX';
  const onlyNumbers = target.replace(DIGIT_REGEX, '');
  return formatString(PHONE_PATTERN, onlyNumbers);
};

export const removeSpecialCharacters = (target?: string) => {
  if (!target || target !== 'string') return '';
  return target.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const formatZipCode = (target?: string) => {
  const ZIP_CODE_PATTERN = 'XXXXX';
  const onlyNumbers = target?.replace(DIGIT_REGEX, '');
  if (onlyNumbers) {
    return formatString(ZIP_CODE_PATTERN, onlyNumbers);
  }
  return;
};

export const formatState = (target?: string) => {
  const STATE_PATTERN = 'XX';
  const onlyLetters = target?.replace(/[^a-zA-Z ]/g, '');
  if (onlyLetters) {
    return formatString(STATE_PATTERN, onlyLetters);
  }
  return;
};

export const parseAddress = (formatted_address?: string) =>
  formatted_address?.split(',').map((str) => str.trim());

export const makeAddressObject = (address?: string) => {
  const addressArray = parseAddress(address);
  return {
    city: addressArray?.[1],
    country: addressArray?.[3],
    secondaryAddress: '',
    state: formatState(addressArray?.[2]),
    street: addressArray?.[0],
    zipCode: formatZipCode(addressArray?.[2]),
  };
};

export const placeToAddress = (place: PlaceDetails) => {
  const address = {} as UserInputAddress;
  place.address_components.forEach(function (item) {
    switch (item.types[0]) {
      case 'street_number':
        address.streetNumber = item.long_name;
        break;
      case 'route':
        address.street = item.long_name;
        break;
      case 'neighborhood':
      case 'locality': // North Hollywood or Los Angeles?
        address.city = item.long_name;
        break;
      case 'administrative_area_level_1': //  Note some countries don't have states
        address.state = item.short_name;
        break;
      case 'postal_code':
        address.zipCode = item.long_name;
        break;
    }
  });
  return address;
};

export const placeToLngLat = (place: PlaceDetails): AddressGeometry => {
  if (
    typeof place.geometry.location.lat !== 'function' &&
    typeof place.geometry.location.lng !== 'function'
  ) {
    return {
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng,
    };
  } else {
    return {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
  }
};

export const adjustAddressToBackend = (address?: UserInputAddress) => {
  if (!address) return null;
  return {
    street: address.streetNumber + ' ' + address.street,
    city: address.city,
    state: address.state,
    zipCode: address.zipCode,
  };
};

export const removeEmptyFields = (obj: any) => {
  return _.transform(obj, (result: any, value, key) => {
    if (_.isObject(value)) {
      value = removeEmptyFields(value);
    }
    if (_.identity(value)) {
      result[key] = value;
    }
  });
};

export const getRawNumber = (phoneNumber: string): string =>
  phoneNumber?.replace(/\D/g, '').replace(/^1/, '');

export const formatSumMoney = (monthlyRent: string): string => {
  const numValue = parseFloat(monthlyRent);
  if (isNaN(numValue) || numValue < 0) {
    return '';
  }
  const hasDecimals = numValue % 1 !== 0;
  const res = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  }).format(numValue);
  return '$' + res;
};

export const formatDateMMMMDDYYYY = (date: string): string => {
  return moment(date).isValid() ? moment(date).format('MMMM DD, YYYY') : '';
};
